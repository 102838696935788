import { gql } from '@apollo/client';

const CONTACT_US = gql`
  mutation ContactUs($name: String!, $message: String!) {
    contactUs(name: $name, message: $message) {
      id
      name
    }
  }
`;

export default CONTACT_US;
