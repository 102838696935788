import { ToastContainer } from 'react-toastify';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {
  createMuiTheme,
  CssBaseline,
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import jssRTL from 'jss-rtl';
import { create } from 'jss';
import ApolloClientComponent from './ApolloClientComponent';
import FirebaseContext from './firebase/context';
import { firebase } from './firebase';
import Terms from './components/Terms';
import About from './components/About';
import Contact from './components/Contact';
import Growth from './static/Growth.svg';
import FullScreenLoading from './components/FullScreenLoading';
import LockProvider from './LockProvider';

const isFacebookApp = function () {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
};

function FacebookNotSupported() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <h3>הדפדפן של פייסבוק לא נתמך</h3>
      <img alt="" src={Growth} style={{ width: '300px' }} />
      <span
        style={{
          fontSize: '16px',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
        }}
      >
        אנא הכנסו לכאן דרך הדפדפן הרגיל DUZ.co.il
      </span>
    </div>
  );
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://e1772bc7accb4f3cb9f3332d77d53102@o491059.ingest.sentry.io/5555942',
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
  });
}

const Application = lazy(() => import('./components/app'));
const Login = lazy(() => import('./components/Login'));
const PasswordReset = lazy(() => import('./components/PasswordReset'));
const Logout = lazy(() => import('./components/Logout'));
const Signup = lazy(() => import('./components/Signup'));
const Home = lazy(() => import('./components/Home'));
const Test = lazy(() => import('./components/Test'));

const direction = 'rtl';
const jss = create({ plugins: [...jssPreset().plugins, jssRTL()] });
const theme = {
  direction: 'rtl',
  palette: {
    primary: '#f7f052ff', // --icterine: #f7f052ff;
    secondary: '#333333ff', // --jet: #333333ff;
    // secondary: "#333333ff", // --jet: #333333ff;
    background: '#f0eff4ff', // --ghost-white: #f0eff4ff;
    // --cadet-blue: #56a3a6ff;
    // --cyan-process: #00a5e0ff;
  },
};

const muiTheme = createMuiTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: '#115293',
    },
    secondary: {
      main: '#961b61',
    },
  },
});

function App() {
  const firstTime = useRef(true);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [fbBrowser, setFbBrowser] = useState(false);

  useEffect(() => {
    if (isFacebookApp()) {
      setFbBrowser(true);
    }
    const loadingElement = document.getElementById('loading-element');
    if (loadingElement) loadingElement.remove();
  }, []);

  useEffect(() => {
    if (!firstTime.current) {
      return;
    }

    firebase.auth.onAuthStateChanged((userAuth) => {
      if (window.ReactNativeWebView) {
        firebase
          .getToken()
          .then((token) => {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                userId: userAuth?.uid,
                token,
              })
            );
          })
          .catch((e) => console.error('Failed to get user token', e));
      }
      setUser(userAuth);
      firebase
        .setToken()
        .then(() => {
          setIsAuthenticated(!!userAuth);
          setLoading(false);
        })
        .catch((e) => {
          console.error('Failed to get token', e);
          setIsAuthenticated(!!userAuth);
          setLoading(false);
        });
    });

    firstTime.current = false;
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        user,
        isAuthenticated,
        loading,
      }}
    >
      <ApolloClientComponent>
        <LockProvider>
          <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
              <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                {fbBrowser ? (
                  <FacebookNotSupported />
                ) : (
                  <>
                    <ToastContainer
                      position="top-right"
                      autoClose={2500}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                    {loading && <FullScreenLoading text="טוען את האפליקציה" />}
                    {!loading && (
                      <div
                        dir={direction}
                        style={{
                          direction,
                          display: 'flex',
                          flexGrow: 1,
                          height: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <Router>
                          <Suspense fallback={<FullScreenLoading text="טוען את האפליקציה" />}>
                            <Switch>
                              <Route exact path="/" component={Home} />
                              <Route exact path="/test" component={Test} />

                              {/* Registration and on boarding */}
                              <Route exact path="/signup" component={Signup} />
                              <Route exact path="/login" component={Login} />
                              <Route exact path="/logout" component={Logout} />
                              <Route exact path="/passwordReset" component={PasswordReset} />
                              <Route exact path="/terms" component={Terms} />
                              <Route exact path="/about" component={About} />
                              <Route exact path="/contact" component={Contact} />
                              <Route exact path="/loading" component={FullScreenLoading} />

                              {/* Application routes */}
                              <Application />
                              {/* Tests */}
                              <Route path="/template" />
                            </Switch>
                          </Suspense>
                        </Router>
                      </div>
                    )}
                  </>
                )}
              </MuiThemeProvider>
            </ThemeProvider>
          </StylesProvider>
        </LockProvider>
      </ApolloClientComponent>
    </FirebaseContext.Provider>
  );
}

export default App;
