import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import LockContext from './contexts/lockContext';
import FirebaseContext from './firebase/context';

const MY_LOCK = gql`
  query MyLock {
    myLock {
      id
      targetId
      createdAt
      mutual
      elapsedDays
    }
  }
`;

export default function LockProvider({ children }) {
  const { isAuthenticated } = useContext(FirebaseContext);
  const { data, loading, refetch } = useQuery(MY_LOCK, {
    pollInterval: 10 * 60 * 1000,
    skip: !isAuthenticated,
  });

  return (
    <LockContext.Provider
      value={{
        isLocked: !!data?.myLock?.mutual,
        lockedUserId: data?.myLock?.targetId,
        createdAt: data?.myLock?.createdAt,
        elapsedDays: data?.myLock?.elapsedDays,
        loading,
        refetch,
      }}
    >
      {children}
    </LockContext.Provider>
  );
}
