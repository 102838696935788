import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import React from 'react';
import ApolloClientContext from './ApolloContext';
import { firebase } from './firebase';

let API_URL = `http://localhost:${process.env.REACT_APP_API_PORT || 8000}/graphql`;
let API_REALTIME_URL = `ws://localhost:${process.env.REACT_APP_API_PORT || 8000}/graphql`;
if (process.env.NODE_ENV === 'production') {
  API_URL = '/graphql';
  API_REALTIME_URL = `wss://${window.location.host}/graphql`;
}

const httpLink = createHttpLink({
  uri: API_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return token
    ? {
        headers: {
          ...headers,
          'x-token': token,
        },
      }
    : headers;
});

const wsLink = new WebSocketLink({
  uri: API_REALTIME_URL,
  options: {
    reconnect: true,
    connectionParams: () => ({
      authToken: localStorage.getItem('token'),
    }),
  },
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const errorLink = onError(({ graphQLErrors, networkError }, operation, forward) => {
  console.error('GraphQL error', graphQLErrors, networkError);
  if (
    (networkError && networkError.message.includes('Your session expired. Sign in again.')) ||
    graphQLErrors?.[0]?.extensions?.code === 'UNAUTHENTICATED'
  ) {
    console.error('Authentication error, reauthenticate');
    return new Observable((observer) => {
      firebase
        .setToken()
        .then((token) => {
          operation.setContext(({ headers = {} }) => ({
            headers: {
              // Re-add old headers
              ...headers,
              // Switch out old access token for new one
              'x-token': token,
            },
          }));
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          // No refresh or client token available, we force user to login
          observer.error(error);
        });
    });
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(splitLink)),
  cache: new InMemoryCache(),
});

export default function ApolloClientComponent({ children }) {
  return (
    <ApolloProvider client={client}>
      <ApolloClientContext.Provider value={{ client }}>{children}</ApolloClientContext.Provider>
    </ApolloProvider>
  );
}
