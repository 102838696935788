/* eslint-disable import/no-mutable-exports */
let firebaseConfig;

if (process.env.NODE_ENV === 'production' && !process.env.REACT_APP_TEST) {
  firebaseConfig = {
    apiKey: 'AIzaSyBfx2D5ftecYT2JtkXThyGUWOlaW_uny4o',
    authDomain: 'duz-production.firebaseapp.com',
    databaseURL: 'https://duz-production.firebaseio.com',
    projectId: 'duz-production',
    storageBucket: 'duz-production.appspot.com',
    messagingSenderId: '180851456683',
    appId: '1:180851456683:web:d0e47d71f73c366bb515ee',
    measurementId: 'G-CFKB7428M7',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyBGPregW2YD5ctmE5U3tg1MCyDVit2CNPI',
    authDomain: 'dos-dating.firebaseapp.com',
    databaseURL: 'https://dos-dating.firebaseio.com',
    projectId: 'dos-dating',
    storageBucket: 'dos-dating.appspot.com',
    messagingSenderId: '843676916388',
    appId: '1:843676916388:web:48b47c267ca9301fa72431',
    measurementId: 'G-YRDN7CYX0G',
  };
}

export default firebaseConfig;
