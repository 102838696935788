import React from 'react';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom';

const Paragraph = styled.div`
  white-space: pre-wrap;
  margin-bottom: 20px;
`;

const DecimalList = styled.ul`
  list-style: decimal;
`;

function TermsText() {
  return (
    <>
      <h1 className="text-gray-800 font-bold text-xl">תנאי שימוש ומדיניות פרטיות</h1>
      <Paragraph>
        {`                  
"דוז" (להלן "החברה")
 
הואיל והחברה הינה בעלת כל הזכויות בתוכנת "דוז" ("התוכנה") לרבות זכויות יוצרים, זכות שיווק והפצה וכל זכות קניינית אחרת;
והואיל והלקוח (להלן: "הלקוח" ו/או  "המורשה") מעוניין לקבל להשתמש בתוכנה לצרכיו בלבד;
והואיל והחברה מסכימה ליתן ללקוח הרשאה לשימוש בתוכנה בהתאם ובכפוף להוראות הסכם זה;
לפיכך הוסכם והותנה בין הצדדים כדלקמן:
                `}
      </Paragraph>
      <Paragraph>
        <h2 className="text-gray-800 font-bold text-lg">תנאי שימוש</h2>
        <h3 className="text-gray-800 font-bold m-2 m-2">1. כללי</h3>
        <DecimalList>
          <li>
            המבוא והנספחים להסכם זה מהווים חלק בלתי נפרד ממנו (להלן כולם יחדיו – &quot;ההסכם&quot;
            או &quot;הרישיון&quot;). בכל מקרה של סתירה יגבר האמור בהסכם זה.
          </li>
          <li>
            חלוקת ההסכם לסעיפים, ומתן הכותרות לסעיפים, נעשו לשם נוחות בלבד ואין לייחס להם כל משמעות
            בפרשנות ההסכם.
          </li>
          <li>
            לשון ההסכם מנוסחת בלשון זכר לצורך הנוכחות בלבד אולם מתייחסת באופן זהה ושווה לשני המינים
            גם יחד.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">2. על הפלטפורמה</h3>
        <DecimalList>
          <li>
            פלטפורמת ״דוז״ הוקמה על מנת לתת מענה היכרות זוגית לדתיים, דתיות ולשומרי מסורת. על כן
            ידוע לך כי השירות אותו החברה מספקת הוא על מנת למצוא זוגיות אמיתית.
          </li>
          <li>
            האוכלוסיה שמשתמשת בשירותי החברה הינה דתית ומסורתית ולכן עליך להתנהג בהתאם ולכבד את צביון
            המשתמשים.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">3. אחריות</h3>
        <DecimalList>
          <li>
            אין כל התחייבות מצד החברה ומכל צד שהוא להצלחת השירות. השימוש בשירות ו/או תשלום המנוי
            אינו עולה בקשר ישיר או עקיף להצלחה במציאת זיווג, אהבה, קשר או כל התקשרות כלשהי בכלל.
          </li>
          <li>
            החברה אינה אחראית לנזק שיגרם למשתמשי האתר בעקבות שירותי ההיכרות אותם מספקת החברה. בין אם
            נעשו באופן ישיר ובין אם נעשו באופן עקיף.
          </li>
          <li>
            החברה אינה מביעה דעה ואינה תומכת בשום מידע, תוכן, שירות, מוצר או הצעה שהגישה אליהם ניתנת
            באמצעות הפלטפורמה או בכל אמצעי אחר. החברה אינה בודקת את פרטי המשתמשים, תוכן, אמיתות
            וכדאיות ההצעות והמידע שהוזן ומוזן על ידי המשתמשים או שנוצר באופן עקיף משימוש בפלטפורמה.
          </li>
          <li>
            החברה אינה מספקת את המידע והתוכן המופיעים בפלטפורמה הנוגעים לצדדים שלישיים. יודגש, המידע
            והתוכן שמונגש בפלטפורמה מיוצר על ידי צדדיים שלישיים.
          </li>
          <li>
            החברה אינה נושאת באחריות לפעולות או מחדלים של משתמשים אחרים העושים שימוש בפלטפורמה.
            החברה לא נושאת על פי כל דין באחריות לכל פגיעה ו/או נזק שייגרם למשתמש בפלטפורמה.
          </li>
          <li>
            שימוש בפלטפורמה מהווה את הסכמתך לפטור את החברה מכל אחריות בשל פעולות של הלקוח, אתה
            ופעולות צד שלישי.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">4. אישור משתמשים ורישום משתמשים</h3>
        <DecimalList>
          <li>
            הגלישה והשימוש באתר מותרים מגיל 18 ומעלה, בהרשמתך לאתר הנך מצהיר שהינך מעל גיל 18.
          </li>
          <li>
            החברה משאירה לעצמה את הזכות לאשר או לא לאשר את הרשמתך לאתר כמו כן לאשר או לא לאשר תכנים
            מסויימים המועלים על ידי משתמשי התוכנה, על פי שיקול דעתה הבלעדי.
          </li>
          <li>
            החברה משאירה לעצמה את הזכות לפסול לפי שיקול דעתה טקסט ו/או כל מדיה ומידע שהועלה על ידי
            המשתמשים מכל טעם שתראה לנכון.
          </li>
          <li>
            על המשתמש להיות אמין, להעלות תמונות של עצמו בלבד ולהזין מידע שנוגע אליו בלבד. החברה
            רשאית להסיר או לשנות מידע שמשתמשים מעלים במידה והיא רואה לנכון מכל טעם שתראה.
          </li>
          <li>
            יצירת משתמש בתוכנה הינה לשימוש אישי בלבד של בעל המשתמש, ולא תתאפשר כל העברה של משתמשים,
            וכן לא יתאפשר שימוש של מספר משתמשים בעזרת אותן נתוני התחברות.
          </li>
          <li>
            החברה רשאית לא לאשר ו/או לחסום משתמשים מכל סיבה שבעיניה אינם מתאימים לרוחו או לתדמיתה של
            התוכנה. החברה לא תתן הסבר למשתמשים שתוכן שהעלו נדחה או השתנה.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">5. שימוש בפלטפורמה</h3>
        <DecimalList>
          <li>
            כל הפלטפורמה לרבות המידע המוצג בה הם רכושה של החברה. אין להעתיק או לפרסם כל כל מידע
            לרבות ממסכים תמונות וטקסט ללא הסכמתה המפורשת של החברה בכתב ומראש.
          </li>
          <li>
            חלק מהשירותים שמוצעים בפלטפורמה דורשים תשלום. על המשתמש לעקוב אחר הוראות התשלום וההרשמה
            על מנת לקבל את השירותים הנ״ל.
          </li>
          <li>
            חל איסור חמור על כל פרסום מסחרי בפלטפורמה ו/או על כל פנייה למשתמשים אחרים שלא לצורך
            היכרות אישית בהתאם לתנאי ולאופי האתר ו/או כל פעילות אחרת מטעם גוף מסחרי, אשר אינה לצורכי
            היכרות אישית בלבד, ובכלל זה פרסום כל דבר באמצעות הפלטפורמה, במישרין ו/או בעקיפין ו/או
            פרסום קישוריות (לינקים) שיובילו לגופים אחרים ושימוש בשירותי ההודעות של הפלטפורמה לכל אחת
            מהמטרות האסורות המפורטות לעיל.
          </li>
          <li>
            החברה שומרת לעצמה את הזכות לשנות את מבנה הפלטפורמה תוכנה וכל דבר שעולה על דעתה לשנות
            בפלטפורמה. כמו כן, תוכל החברה לשנות כל דבר שקשור בתוכנה עצמה ובהצגת התוכן למשתמש. אין
            החברה אחראית לטעויות בהצגת נתונים במידה ויווצרו בעקבות כך טעויות או בעיות כאלו ואחרות.
          </li>
          <li>
            משתמש הנרשם לשירות, מודע לכך שהחברה רשאית להשתמש במידע שאותו מעביר בפלטפורמה בכל צורה
            שהחברה תראה לנכון בין אם זה הצגת המשתמש בפלטפורמה בנייד במחשב ובכל מדיה אפשרית ובין אם
            זה בעיבוד המידע בעזרת טכנולוגיה כזו או אחרת או בעזרת צד שלישי.
          </li>
          <li>החברה רשאית לבחור לנכון להציג משתמשים באיזה סדר וצורה שתרצה.</li>
          <li>
            החברה אינה אחראית לכל נזק שעשוי להיגרם מהיעדר זמינותה התמידית של הפלטפורמה, כמו כן, לא
            לתקלות טכניות ,למידע שיישמר בפלטפורמה, וכן, לנזק שיכול להיגרם מהפסקת פעילות.
          </li>
          <li>
            החברה תציג את המידע שמוזן בה בכל דרך שתבחר לנכון לרבות לשנות את סדר הופעת המשתמשים,
            לשנות את תצוגת התמונות, את הטקסט שמשתמשים מזינים וכד׳. בהרשמתך לפלטפורמה, הינך מצהיר
            שאתה מסכים לעובדה זאת.
          </li>
          <li>
            מבלי לגרוע מהאמור לעיל, בכניסתך לפלטפורמה אתה מתחייב במפורש שלא:
            <DecimalList>
              <li>
                לעשות כל שימוש במידע על משתמשי הפלטפורמה ו/או בתוכן מכל סוג שהוא, לרבות, אך מבלי
                להגביל, בתמונות, בסרטונים ובקטעי אודיו, שאין לך את הזכות ו/או הרשות לעשות בו שימוש.
              </li>
              <li>למסור פרטיו של אחר, זולת המשתמש, למעט, בכפוף לאישורו המפורש של אותו משתמש.</li>
              <li>
                להטריד, לאיים, &quot;לעקוב&quot;, לפגוע, להעליב, ולהפר כל זכות משפטית שעומדת לאדם או
                לגוף אחר.
              </li>
              <li>
                להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן בעל אופי שאינו הגון, ראוי, פורנוגראפי,
                משמיץ או שאינו חוקי.
              </li>
              <li>
                להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן שמטרתו היא מסחרית, שמטרתו פרסום, שידול
                לרכישה, מתן שירותים, תרומות או שמטרתו להוליך שולל משתמש, לרבות להעלות, לפרסם, להגיב,
                לכתוב, לאסוף, כל תוכן מסחרי ללא קבלת היתר מפורש, בכתב ומראש מהחברה.
              </li>
              <li>
                להעלות, לפרסם, להגיב, לכתוב, לאסוף כל חומר שעלול להוות עבירה פלילית, עלול ליצור עילה
                אזרחית, שמפר דין ישראלי או דין של מדינה אחרת.
              </li>
              <li>
                לאסוף, מידע לגבי משתמשים אחרים, לרבות, ולא רק, איסוף כתובות דואר אלקטרוני, מידע
                אישי, תמונות או חומרים אחרים ברשתות חברתיות של משתמשים אחרים.
              </li>
              <li>
                להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן שיש בו כדי לפגוע בביטחונה של מדינת ישראל
                ו/או שיש בו כדי להפר סוד צבאי ו/או צנזורה צבאית.
              </li>
              <li>
                להעלות, לפרסם, להגיב, לכתוב, לאסוף, כל תוכן שיש בו כדי לפגוע בביטחונה של מדינת ישראל
                ו/או שיש בו כדי להפר סוד צבאי ו/או צנזורה צבאית.
              </li>
              <li>
                להפעיל או לאפשר להפעיל כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג כלשהו, לשם
                חיפוש, סריקה, העתקה או אחזור אוטומטי של תכנים מתוך הפלטפורמה. בכלל זה, אין ליצור
                ואין להשתמש באמצעים כאמור לשם יצירת לקט, אוסף או מאגר שיכילו תכנים מהפלטפורמה.
              </li>
              <li>
                להציג תכנים מהפלטפורמה בכל דרך שהיא ובכלל זה באמצעות כל תוכנה, מכשיר, אביזר או
                פרוטוקול תקשורת - המשנים את עיצובם בפלטפורמה או מחסירים מהם תכנים כלשהם ובפרט
                פרסומות ותכנים מסחריים.
              </li>
              <li>
                לבצע שינויים בפלטפורמה או להעתיק, להפיץ, לשדר, להציג, לבצע, לשכפל, לפרסם, לתת היתר
                או רישיון, ליצור עבודות נגזרות או למכור פריט מפריטי המידע, התוכנה, המוצרים או
                השירותים שמקורם בפלטפורמה.
              </li>
              <li>לשתול קודים ו/או וירוסים ו/או תוכנות בפלטפורמה.</li>
              <li>לשלוח דואר זבל לחברי הפלטפורמה או להתחיל מכתבי שרשרת כלשהם.</li>
            </DecimalList>
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">6. שירותי צד שלישי</h3>
        <DecimalList>
          <li>
            הלקוח עשוי להיחשף לשירותים, מוצרים והצעות שלא מצד החברה, אלא מאת צדדים שלישיים, בקשר
            לשימושו בתוכנה. באם המורשה מחליט להשתמש בשירותים אלו, חלה עליו האחריות הבלעדית לקרוא
            ולהבין את תנאי השימוש לגבי אותו מוצר או שירות המסופק על ידי צד-שלישי. הלקוח מאשר בזאת כי
            החברה לא תישא באחריות כלשהי לגבי אותו מוצר או שירות של צד שלישי והשימוש בו, לרבות כל
            תוצאה הנובעת משימוש זה והאחריות תחול על הלקוח באופן בלעדי. הלקוח מאשר בזאת לחברה להעביר
            את פרטי מידע הרישום הדרוש ליצירת קשר עימו, לרבות שמו וכתובתו, וזאת בכדי לאפשר מתן שירותי
            צד-שלישי.
          </li>
          <li>
            התוכנה עשויה להכיל קישורים או לאפשר גישה לאתרי אינטרנט וליישומים שאינם מופעלים על ידי
            החברה, אלא בידי גורמים אחרים. קישורים אלו נועדו לנוחיות המורשה בלבד. אין לחברה שליטה
            באתרי אינטרנט או יישומים אלו, ואין היא נושאת באחריות לתכנים המופיעים בהם ו/או לשירותים
            הניתנים בהם. אין במתן הגישה לאתרים וליישומים אלו באמצעות התוכנה משום אישור לחומר המופיע
            בהם, ו/או להקנות זכות שימוש כלשהי במידע ובתכנים המצויים בהם ו/או כדי להעיד על קשר כלשהו
            עם מפעילי האתרים האמורים וכל שימוש באתרים או יישומים אלו הינו באחריות המשתמש בהם בלבד.
          </li>
        </DecimalList>
      </Paragraph>
      <h2 id="privacy" className="text-gray-800 font-bold text-lg">
        מדיניות פרטיות
      </h2>
      <Paragraph>
        <h3 className="text-gray-800 font-bold m-2">1. כללי</h3>
        <DecimalList>
          <li>אם אינך מסכים למדיניות השמירה על הפרטיות, הינך נדרש שלא להירשם לשירותי החברה.</li>
          <li>
            הפרטיות שלך חשובה לנו. על מנת להבין טוב יותר איזה מידע אנו אוספים ומהו השימוש שאנו עושים
            במידע זה, אנא קרא את מדיניות הפרטיות המפורטת להלן.
          </li>
          <li>
            מדיניות פרטיות זו חלה על מידע הנאסף במסגרת השימוש שלך ביישומי החברה בלבד ובהתאם להסכמת
            המשתמש בתנאים שנזכרים להלן.
          </li>
          <li>בעצם השימוש בתוכנה אתה מסכים ומאשר את מדיניות הפרטיות שלהלן</li>
          <li>
            החברה עשויה לשנות ו/או לערוך ו/או לעדכן את מדיניות הפרטיות מעת לעת. אתה מוזמן לקרוא את
            מדיניות הפרטיות מדי פעם על מנת להתעדכן בשינויים.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">2. מידע אודות השימוש</h3>
        <DecimalList>
          <li>
            החברה רשאית לאסוף, לעבד ולשמור (לרבות בשרתים מחוץ לישראל) את המידע המפורט להלן ולעשות בו
            שימוש על פי מדיניות פרטיות זו:
          </li>
          <li>
            כל מידע אשר נמסר לחברה בעת ההרשמה והשימוש בפלטפורמה. ובכלל זה עשויים לכלול פרטים אישיים,
            המוגדרים כ&quot;מידע רגיש&quot;, על פי דין, כגון: שם, תצלומים, מקום מגורים, עיסוק,
            תחביבים, תחומי עניין, נתונים פיזיים ותכונות ומאפיינים אישיים שונים, תאריך לידה, מצב
            אישי, השכלה, התכתבות בינך לבין משתמשים אחרים, מידע פיננסי (כגון פרטי כרטיס אשראי)
            וכיו&quot;ב (להלן: &quot;הפרטים האישיים&quot;), אשר ייאספו, יעובדו וישמרו ויעשה בהם
            שימוש על ידי החברה.
          </li>
          <li>
            פעולות שונות בפלטפורמה עשויות לדרוש ממך לספק פרטים שונים, כגון: שם מלא, מספרי טלפון,
            כתובות דואר ודואר אלקטרוני, מספר תעודת זהות ומספר כרטיס אשראי, אשר ייתכן ויאספו ו/או
            יאוחסנו על ידי החברה.
          </li>
          <li>
            מידע אודות השימוש שלך בפלטפורמה, מידע אודות פעולות ו/או עסקאות הנעשות באמצעות הפלטפורמה,
            כל מידע בנוגע לשימוש ולצפיה בפלטפורמהו/או כל מידע אחר שאתה מוסר ו/או מעלה ו/או מפרסם על
            גבי האתר בין עם יאוחסן בשרתי החברה ובין אם יאוחסן בגורם צד שלישי.
          </li>
          <li>
            מידע אישי בדבר מיקום המשתמש, לרבות שמירה של היסטוריית המיקומים של המשתמש, כתובת IP, מידע
            בדבר איכון המיקום.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">3. הצגת מיקום ושימוש במיקום</h3>
        <DecimalList>
          <li>
            החברה רשאית להציג את מיקום המשתמש במצב הנוכחי או היסטורית המיקום שלו במידה והמשתמש אישר
            הצגה של מיקום.
          </li>
          <li>
            החברה רשאית לעשות שימוש במידע איכון המיקום, על מנת לנהל מסעות פרסום, בין היתר, על בסיס
            מיקום גיאוגרפי.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">4. הצגת ושימוש במידע פרטי בתוכנה </h3>
        <DecimalList>
          <li>
            החברה רשאית להשתמש בכל מידע שהמשתמש העלה לפלטפורמה ולהציג אותו למשתמשים אחרים בין אם
            שליחת המידע נעשתה באופן ישיר ובין אם בעקיפין.
          </li>
          <li>בנוסף, המידע הנזכר לעיל עשוי בין השאר לשמש לצורך:</li>
          <DecimalList>
            <li>שיפור חווית המשתמש שלך בפלטפורמה ושימוש בשירותי החברה;</li>
            <li>התאמה אישית של הפלטפורמה ושירותים נוספים של החברה ו/או מי מטעמה;</li>
            <li>ניהול טוב יותר את החברה ויישומיה;</li>
            <li>משלוח הדו&quot;חות, תקשורת שיווקית, מיילים בדבר שירותים, פרסומות וכיו&quot;ב;</li>
            <li>עיבוד העסקאות שנעשו באמצעות יישומי החברה;</li>
            <li>פרסום מידע ביישומי החברה אודותיך;</li>
            <li>
              הצגה או משלוח של חומרי שיווק ופרסום, לרבות שימוש במידע איכון על מנת להציג פרסומות
              מותאמות מבוססות נתונים ו/או מיקום גיאוגרפי.
            </li>
            <li>
              החברה עשוייה לעשות שימוש במידע פיננסי שלך בפלטפורמה על מנת לבצע התאמה לשימוש בפלטפורמה
              וכן על מנת לבצע חיובים קבועים ומתמשכים.
            </li>
          </DecimalList>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">5. שימוש במידע על ידי צדדים שלישיים</h3>
        <DecimalList>
          <li>
            החברה יכולה להשתמש בנתונים ומידע שנאספו על המשתמש ו/או להעביר אותן לגורם צד שלישי לכל
            סיבה שלא תהיה.
          </li>
          <li>
            בנוסף, החברה עשוייה לחשוף מידע אישי ופרטי אם וכאשר אנו סבורים כי שחרור כזה הוא ראוי ו /
            או נחוץ בכדי לציית לחוק, כדי לקיים את פסיקת בית משפט ו / או צו בית משפט, כדי להגן על
            זכויותינו ועל זכויותיהם של צדדים אחרים ו / או על רכושם ועל מנת לאכוף את המדיניות באתר
            וביישומיו.
          </li>
          <li>
            בנוסף, אנו עשויים לעשות שימוש במידע אודותיך לצורך הצגה מותאמת של תכנים במערכות Google ,
            Facebook וכיו&quot;ב.
          </li>
          <li>
            החברה עשוייה לעשות שימוש בשירותי חברת פרסום כדי להציג מודעות מטעמנו ברחבי הרשת. חברות צד
            שלישי כמו כן עשויות לאסוף מידע פרטי על פרטי השימוש שלך בפלטפורמה.
          </li>
          <li>
            אנו עשויים לספק מידע פיננסי אודותיך לספקי השירותים שלנו ולכל צד שלישי וזאת לצורך תשלום
            על מנוי כזה או אחר, תמיכה וייעול הפעילויות שלך בפלטפורמה. צד שלישי זה עשוי להיות, חברות
            כרטיסי האשראי ומוסדות בנקאיים המטפלים ותומכים בעסקה או בפעילות האתר. אותם צדדים שלישיים
            יהיו רשאים לעשות שימוש במידע הפיננסי אודותיך, בכפוף להסדרים החוזיים שלנו עם צדדים
            שלישיים אלה, ובהתאם למדיניות לשמירה הפרטיות של אותם גופים.
          </li>
          <li>
            ככל ויוצגו בפניך פרסומות באתר הן עשויות להיות מוצגות באמצעות מערכת פרסום חיצונית של צד
            שלישי.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">6. תקשורת</h3>
        <DecimalList>
          <li>
            מספר הטלפון שלך, ו/או כתובת דואר אלקטרוני שסופקה על ידך לחברה לצורך רישום ו/או עיבוד
            תקשורת ו/או הזמנות ו/או יצירת קשר ו/או עסקאות מכל סוג שהוא, תשמש למטרה של שליחת מידע,
            פרסומים, ועדכונים לשימוש שלך בפלטפורמה, כמו גם לתקשורת עמך ו/או להזמנות שלך ולצרכים
            המפורטים במדיניות פרטיות זו, כפי שתעודכן מעת לעת.
          </li>
          <li>
            החברה ו/או מי מטעמה, באמצעותה ובאמצעות צדדים נוספים מטעמה, רשאים לפנות למשתמשי האתר
            באמצעות הדואר האלקטרוני לצרכי: פרסום, תקשורת ומשלוח הודעות שונות. כמו כן החברה רשאית
            לעשות זאת גם באמצעות מספר הטלפון אותו שייכת למשתמש.
          </li>
          <li>
            אם וכאשר אתה מעדיף להפסיק לקבל חומרי שיווק, אנא צור קשר עם כתובת המייל
            duzdating@gmail.com .
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">7. אבטחת מידע</h3>
        <DecimalList>
          <li>
            אנו ננקוט באמצעי זהירות טכניים וארגוניים סבירים כדי למנוע אובדן, שימוש לרעה או פגיעה
            במידע האישי שלך.
          </li>
          <li>
            אנו נוקטים באמצעים שונים כדי להבטיח את המידע האישי שהמשתמשים בפלטפורמה נדרשים לספק לצורך
            גישה, כניסה, שליחה של מידע ותקשורת, או בכדי לשנות את המידע האישי שניתן על ידי המשתמש.
          </li>
          <li>
            בהתאם לאופי הפעילות, וכפי שקרה בעבר באתרים שונים בעולם. עלולים להיות מצבים שבו מידע רגיש
            מאתרי הכרויות דלף החוצה באמצעות שימוש לא חוקי, פריצה או מעשי זדון אחרים. עליך להימנע
            ממסירת מידע שאתה חושב שיש בו כדי לפגוע בפרטיותך.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">8. עוגיות (cookies)</h3>
        <DecimalList>
          <li>
            עוגייה היא מחרוזת אותיות ומספרים המשמשת לאימות, למעקב ולאגירת מידע על אודות גולש באתר
            אינטרנט, כגון שמירת העדפות המשתמש.
          </li>
          <li>
            ההסכמה לשימוש שלנו בעוגיות בהתאם לתנאים של מדיניות זו בזמן הביקור הראשון בפלטפורמה,
            מאפשרת לנו להשתמש בעוגיות בכל פעם שאתם מבקרים בה.
          </li>
          <li>
            אנו משתמשים בעוגיות למטרות שונות, לרבות, על מנת לפקח ולנתח נתונים על התנועה בפלטפורמה,
            לזכור ולנתח פריטים המועברים על ידי המשתמשים, כדי לשמור ולהתאים את העדפות המשתמש לשימוש
            עתידי, חיסכון בצורך להזין מחדש את שם המשתמש והסיסמה בכל כניסה לשירות, על מנת להקל על
            השימוש, על מנת לאסוף מידע סטטיסטי, לאמת את המידע, וכן לצורך אבטחת מידע וכל מטרה אחרת
            המפורטת במדיניות פרטיות זו.
          </li>
          <li>
            ניתן לחסום עוגיות דרך הגדרות הדפדפן. אפשרות זו עלולה לגרום לשירותים מסוימים שלא לפעול
            כראוי.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">9. מחיקת מידע אישי</h3>
        <DecimalList>
          <li>
            על מנת למחוק את המשתמש וכל הנתונים הנלווים לו יש ליצור בקשה בעמוד ״צור קשר״ באתר או
            לשלוח בקשה לכתובת המייל duzdating@gmail.com .
          </li>
          <li>עם קבלת בקשת משתמש למחיקת המשתמש אנו נפעל להפסקת הצגת המידע הפרטי שלך בפלטפורמה.</li>
          <li>
            אם ברצונך כי פרטייך יימחקו כליל מן מהמאגר, או אם אין ברצונך להמשיך ולקבל מידע מסחרי
            כאמור, וברצונך לבטל את הסכמתך ולחדול מקבלת המידע המסחרי, תוכל לעשות זאת, בכל עת באמצעות
            פניה לכתובת duzdating@gmail.com ואולם מחיקה מוחלטת מהמאגרים שלנו כפופה לסייגים הבאים:
            <DecimalList>
              <li>
                החברה תעשה כל מאמץ למחוק המידע האפשרי, אולם ייתכן שהדבר לא יוכל להתבצע באופן מיידי.
              </li>
              <li>
                ייתכן ולא ניתן יהיה לבצע מחיקה של הנתונים ממערכות גיבוי או גורמים צד שלישיים כאלו
                ואחרים שמידע שנשלח על ידך מאוחסן שם.
              </li>
              <li>
                כדי לאפשר לנו לבדוק, למנוע ולדווח לרשויות החוק על שימוש לרעה באתר אנו עשויים שלא
                לאפשר את המחיקה של הנתונים הבאים: שם משתמש, מועד הרשמה, מועד ביצוע פעולות, כתובות
                IP. גם במקרה של חסימת משתמש על ידי האתר, הפרטים הרלוונטיים ישמרו וזאת בין היתר על
                מנת למנוע רישום מחודש לאתר.
              </li>
              <li>
                אנו עשויים לשמור במאגר מידע שנועד לצורך יישוב מחלוקות, לאיתור וטיפול בבעיות ולאכיפת
                הכללים ותנאים שלנו.
              </li>
            </DecimalList>
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">10. שירותים ומידע של צד שלישי</h3>
        <DecimalList>
          <li>
            יובהר, כי אין באפשרות החברה לפקח או לנטר על מידע שמועבר מגורמים חיצוניים ועל כן לא נהיה
            אחראים להפרות פרטיות הנגרמות עקב הזדהות או העברת נתונים מגורמים חיצוניים אלו.
          </li>
          <li>
            יובהר, כי במתן הרשאה לחיבור עם גורמים חיצוניים הנך מאפשר לחברה גישה ושימוש מלא לפרטים
            ולמדיה אשר העלית לאותם גורמים.
          </li>
        </DecimalList>
        <h3 className="text-gray-800 font-bold m-2">11. תנאי שימוש</h3>
        <DecimalList>
          <li>
            השימוש ביישומי החברה כפוף לתנאי השימוש בה, המפרטים ומרחיבים את תנאי השימוש, הויתורים
            וההגבלות השונות. בעצם השימוש ביישומי החברה אתה נחשב כמי שקרא והסכים לתנאי השימוש בה.
          </li>
          <li>ניתן לעיין בתנאי השימוש של החברה בכתובת: /terms.</li>
          <li>
            שים לב כי נציגי החברה בודקים דיווחים שונים של משתמשים שונים בקשר לתוכן שמועלה ממשתמשים
            שונים באתר. לרבות ביחס לאופיה של הפלטפורמה, הפונה לקהל דתי ושומר מסורת. הינך מנוע מלטעון
            כל טענה בקשר לכך.
          </li>
          <li>הנני מאשר בכניסתי לפלטפורמה ובהרשמה את כל תנאי רישיון השימוש הכתובים לעייל.</li>
        </DecimalList>
        &nbsp;
      </Paragraph>
    </>
  );
}

export default function Terms() {
  const history = useHistory();

  return (
    <div className="flex flex-col items-center p-10 overflow-auto text-gray-700">
      <div className="p-2 w-full">
        <ArrowForwardIosIcon className="cursor-pointer" onClick={() => history.goBack()} />
      </div>
      <div className="w-9/12">
        <TermsText />
      </div>
    </div>
  );
}
