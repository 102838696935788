import * as React from 'react';

const LockContext = React.createContext({
  isLocked: false,
  lockedUserId: undefined,
  createdAt: undefined,
  loading: true,
});

export default LockContext;
