import { Button, createMuiTheme, ThemeProvider } from '@material-ui/core';
import ContactlessIcon from '@material-ui/icons/Contactless';
import HomeIcon from '@material-ui/icons/Home';
import React from 'react';
import { Link } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';

const menuTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#bbdefb',
      contrastText: '#fff',
    },
    /* secondary: {
      main: blue[500],
      contrastText: '#fff',
    }, */
  },
});

export default function UnregisteredMenu() {
  return (
    <ThemeProvider theme={menuTheme}>
      <Button
        component={Link}
        to="/"
        color="primary"
        style={{ color: 'white', padding: '10px 20px' }}
      >
        <HomeIcon />
        &nbsp; בית
      </Button>
      <Button
        component={Link}
        to="/about"
        color="primary"
        style={{ color: 'white', padding: '10px 20px' }}
      >
        <InfoIcon />
        &nbsp; מי אנחנו
      </Button>
      <Button
        component={Link}
        to="/contact"
        color="primary"
        style={{ color: 'white', padding: '10px 20px' }}
      >
        <ContactlessIcon />
        &nbsp; צור קשר
      </Button>
    </ThemeProvider>
  );
}
