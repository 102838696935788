import React from 'react';
import styled from 'styled-components';
import ResponsiveRenderer from './ResponsiveRenderer';
import UnregisteredMenu from './UnregisteredMenu';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  background-color: #4d6d8c;
  /* background-color: #9bb9c7; */
  height: 100%;
`;

const Paragraph = styled.div`
  white-space: pre-wrap;
  margin-bottom: 20px;
`;

function AboutText() {
  return (
    <>
      <h1>מי אנחנו? 🎳</h1>
      <Paragraph>
        {`אנחנו קבוצה של דתיים ודתיות רווקים, בזוגיות ונשואים. כולנו חווינו בצורה כזו או אחרת את הקושי במציאת זוגיות אמיתית וטובה. למצוא אדם בעל השקפת עולם דומה לשלך ושבנוסף לכך תהיה ביניכם התאמה אישית טובה שתחזיק לתמיד. ״תקופת הקורונה״ מקשה עוד יותר על היכולת לרקום קשרים חברתיים חדשים, אנחנו כאן כדי לעזור. 

`}
      </Paragraph>
      <Paragraph>
        <h1>מה זה דוז? 🙌</h1>
        {`״דוז״ היא פלטפורמת הכרויות חדשנית וחכמה שתעזור לך למצוא התאמה זוגית.

כדי למצוא התאמה אמיתית בשבילך תתבקש/י לענות על מספר שאלות שנועדו לכך שהפלטפורמה תלמד אותך טוב יותר ותוכל לתת לך התאמה מדוייקת יותר. אבל זה לא נגמר כאן! במערכת אלמנטים שיעזרו לך להבין שהזוגיות תהיה אמיתית - לדוגמה, הגבלת היכולת לדבר עם מספר משתמשים במקביל ברגע שהחלטתם ששווה לנסות (אל דאגה, זה נעשה בצורה קלילה וכיפית וכמובן, תמיד אפשר לשנות😉).

״דוז״ היא חלק מפרוייקט חברתי שבו כולם עוזרים לכולם; ישנם הרבה זוגות שההיכרות הראשונית שלהם הייתה דרך גורם שלישי - חבר, חברה או סתם אדם שמכיר את שני הצדדים והצליח להפגיש את האחת עם האחד. אנחנו מאמינים בדרך הזו ובפלטפורמה אלמנטים נוספים שתומכים בכך (רמז - בקרוב תגידו לחבריכם הנשואים להצטרף).
`}
      </Paragraph>
      <h1>אנחנו צריכים אתכם 💪</h1>
      {`אתם זה דוז ודוז זה אתם. אנחנו יותר מנשמח לשמוע על רעיונות שיפורים ודברים שאתם חושבים שצריך לעשות אחרת.
      הפלטפורמה הוקמה בשבילכם ורק בשבילכם! כל ביקורת תילקח ברצינות ובשמחה.
      `}
      <br />
      <Paragraph>
        {`
ההרשמה המוקדמת פתוחה!

הרשמו עכשיו וברגע ש״דוז״ תעלה לאוויר תהנו מחודש שימוש חינם
`}
      </Paragraph>
    </>
  );
}

export default function About() {
  return (
    <ResponsiveRenderer
      desktop={
        <>
          <Root>
            <div
              style={{
                width: '100%',
                padding: '10px 40px',
                fontSize: '24px',
              }}
            >
              <UnregisteredMenu />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: 'white',
                width: '100%',
                overflowY: 'auto',
              }}
            >
              <div style={{ color: 'white', maxWidth: '800px' }}>
                <AboutText />
              </div>
            </div>
          </Root>
        </>
      }
      mobile={
        <Root>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              padding: '10px',
            }}
          >
            <UnregisteredMenu />
          </div>
          <div
            style={{
              overflowY: 'auto',
              color: '#eeeeee',
              flexGrow: 1,
              padding: '10px',
            }}
          >
            <AboutText />
          </div>
        </Root>
      }
    />
  );
}
