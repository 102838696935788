import React from 'react';

export default function FullScreenLoading({ text }) {
  return (
    <div
      style={{ background: '#282c34' }}
      className="flex flex-col items-center justify-center w-full h-full"
    >
      <ScreenLoading text={text} />
    </div>
  );
}

export function ScreenLoading({ text, filter }) {
  return (
    <>
      <img
        alt="loading"
        style={{
          width: '100px',
          height: '100px',
          filter,
          WebkitFilter: filter,
        }}
        src={`${process.env.PUBLIC_URL}/rings-loading.svg`}
      />
      {text && <div className="text-center text-white direction: rtl">{text}</div>}
    </>
  );
}
