import { useMutation } from '@apollo/client';
import { Button, TextField } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CONTACT_US from './app/graphql/contactUs';

function ContactUsForm() {
  const [name, setName] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageSent, setMessageSent] = useState(false);
  const [contactUs] = useMutation(CONTACT_US);

  const handleSend = () => {
    contactUs({ variables: { name, message } }).then(() => setMessageSent(true));
  };

  return (
    <>
      {messageSent && <div>ההודעה נשלחה!</div>}
      {!messageSent && (
        <>
          <TextField
            variant="outlined"
            fullWidth
            label="שם פרטי ומשפחה"
            margin="dense"
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            variant="outlined"
            fullWidth
            label="הודעה"
            margin="dense"
            multiline
            onChange={(e) => setMessage(e.target.value)}
            /* styles the wrapper */
            style={{ height: '120px' }}
            /* styles the label component */
            InputLabelProps={{
              style: {
                height: '100px',
              },
            }}
            /* styles the input component */
            inputProps={{
              style: {
                height: '100px',
              },
            }}
          />
          <Button
            variant="contained"
            style={{ margin: '10px 0' }}
            fullWidth
            disabled={!name || !message || message.length < 10}
            onClick={handleSend}
          >
            שלח
          </Button>
        </>
      )}
    </>
  );
}

export default function Contact() {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center w-full p-10 overflow-auto">
      <div className="md:9/12 text-gray-700">
        <div className="p-2 w-full">
          <ArrowForwardIosIcon className="cursor-pointer" onClick={() => history.goBack()} />
        </div>
        <div className="m-10">
          <h1 className="text-gray-800 font-bold text-lg mt-5">צור קשר</h1>
          <div className="py-5">
            החוויה שלכם חשובה לנו!
            <br />
            אתם מוזמנים ליצור קשר בכל נושא שתבחרו. אנחנו מבטיחים להתייחס ברצינות לכל הודעה
          </div>
          <ContactUsForm />
        </div>
      </div>
    </div>
  );
}
