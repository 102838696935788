import app from 'firebase/app';
import firebaseConfig from './config';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

export default class Firebase {
  constructor() {
    // Initialize Firebase
    app.initializeApp(firebaseConfig);
    app.analytics();
    this.auth = app.auth();
    // this.store = app.firestore();
    // this.storage = app.storage();
  }

  // eslint-disable-next-line class-methods-use-this
  get store() {
    return app.firestore();
  }

  // eslint-disable-next-line class-methods-use-this
  get storage() {
    if (process.env.NODE_ENV === 'production') {
      return app.app().storage('gs://images.duz.co.il');
    }

    return app.storage();
  }

  doCreateUserWithEmailAndPassword(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  doSignInWithEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  setToken() {
    if (!this.auth.currentUser) {
      return Promise.resolve();
    }

    return this.auth.currentUser
      .getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        localStorage.setItem('token', idToken);
        return idToken;
      })
      .catch((error) => {
        console.error('Failed to get user token from firebase', error);
      });
  }

  getToken() {
    if (!this.auth.currentUser) {
      return Promise.resolve();
    }

    return this.auth.currentUser.getIdToken(/* forceRefresh */ false);
  }

  doSignOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
    return this.auth.signOut();
  }

  passwordReset(email) {
    return this.auth.sendPasswordResetEmail(email);
  }
}

const firebase = new Firebase();

export { firebase };
